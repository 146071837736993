<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keyup.esc="cancel">
        <v-card flat class="disable-select">
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn :color="$variables.v.theme.secondary"
                       text
                       @click.native="agree">{{$t('dialog-confirm.yes')}}
                </v-btn>
                <v-btn color="grey"
                       text
                       @click.native="cancel">{{$t('dialog-confirm.cancel')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    // Aufruf mit
    // if (await this.$refs.confirm.open('Titel', 'Sind sie sicher?', {color: 'red'})) {
    export default {
        data() {
            return {
                dialog : false,
                resolve: null,
                reject : null,
                message: null,
                title  : null,
                options: {
                    color : this.$variables.v.theme.secondary,
                    width : 290,
                    zIndex: 200
                }
            };
        },
        watch  : {
            dialog() { // Wird benötigt weil sonst beim schließen (klick auserhalb) dieses Dialogs die Methoden die Ihn aufrufen nicht weiterarbeiten.
                if (!this.dialog && this.resolve !== null)
                    this.cancel();
            }
        },
        methods: {
            open(title, message, options) {
                this.dialog  = true;
                this.title   = title;
                this.message = message;
                this.options = Object.assign(this.options, options);
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject  = reject
                })
            },
            agree() {
                this.resolve(true);
                this.dialog = false
            },
            cancel() {
                this.resolve(false);
                this.dialog = false
            }
        }
    }
</script>
@Izopi4a
